import { Controller } from "stimulus";
import Chocolat from 'chocolat'

export default class extends Controller {
  static targets = [""];

  connect() {
    Chocolat(this.element.querySelectorAll('.chocolat-image'), {
      loop: true
    })
  }

  

  disconnect() {
   
  }
}
